import React from "react";
import { Box, Grid } from "@mui/material";
import RequestSummary from "../../request/components/RequestSummary";
import CustomerDetailsButton from "../../common/component/CustomerDetailsButton";
import MyCloseRequest from "../screen/MyCloseRequest";
import CustomerContactInfoButton from "../../common/component/CustomerContactInfoButton";
import BakerChat from "../../quote/component/action/BakerChat";

export default function SendQuoteHeader(props) {
  const { request, quote, status } = props;

  return (
    <Box>
      <Box>
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={6} xs={6}>
            {request.customer?.id ? (
              <CustomerDetailsButton
                customer={request.customer}
              />
            ) : (
              <CustomerContactInfoButton
                customer={{
                  firstName: request.firstName,
                  lastName: request.lastName,
                  name: request.name
                }}
              />
            )}
          </Grid>
          <Grid item sm={6} xs={6} align="right">
            {request.src === "direct" && <BakerChat request={request} quote={quote} status={status} />}
            {request.src === "direct" && request.status !== "closed" && <MyCloseRequest request={request} />}
          </Grid>
        </Grid>
      </Box>
      <RequestSummary request={request} />
    </Box>
  );
}
