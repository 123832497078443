import React, { useContext, useEffect, useState } from "react";
import { Box, Container, IconButton, LinearProgress, Paper, Tab, Tabs, Typography } from "@mui/material";
import { capitalize, formatPhoneNumber, hasManagerAccess } from "../../common/util";
import bkstApi from "../../api";
import { useParams } from "react-router-dom";
import ClickToCopy from "../../common/ClickToCopy";
import ClickToCall from "../../common/ClickToCall";
import LoyaltyStatus from "../../common/component/LoyaltyStatus";
import { UserContext } from "../../context/UserContext";
import BakerOrderSummary from "../../baker/components/BakerOrderSummary";
import NothingFound from "../../common/search/NothingFound";
import currency from "currency.js";
import BakerQuoteSummary from "../../baker/components/BakerQuoteSummary";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

export default function CustomerDetail(props) {
  const { user } = useContext(UserContext);
  let { customerId } = useParams();
  if (!customerId) {
    customerId = props.customerId;
  }

  const [customer, setCustomer] = useState("");
  const [loading, setLoading] = useState(true);

  const [tab, setTab] = useState(0);

  useEffect(() => {
    bkstApi(`/my/customer/${customerId}`).then((res) => {
      setCustomer(res.data.customer);
      setLoading(false);
    });
  }, [customerId]);

  let hasManagerAccess = user.role === "owner" || user.role === "manager";
  let hasQuoteAccess = hasManagerAccess || user.role === "clerk";

  if (loading) {
    return <LinearProgress />;
  } else {
    let orderSummary = "";
    if (customer.orders?.length === 0) {
      orderSummary = "No Orders Yet";
    } else {
      orderSummary = `${customer.orders.length} ORDERS`;
    }

    if (hasManagerAccess) {
      orderSummary += ` • ${currency(
        customer.orders.reduce((acc, o) => +acc + +(o.src === "mkt" ? o.payment.bakerTotal : o.payment.total), 0),
        { precision: 0 }
      ).format()}
          SPEND`;
    }

    return (
      <Container maxWidth="sm">
        <Box my={6}>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box sx={{ flexGrow: "1" }}>
              <Typography variant="h4">
                {customer.firstName} {customer.lastName} {customer?.loyaltyLevel && <LoyaltyStatus status={customer.loyaltyLevel} />}
              </Typography>
              <Typography variant="overline" color="textSecondary">
                {orderSummary}
              </Typography>
            </Box>
            {hasQuoteAccess && (
              <Box>
                <IconButton
                  component={RouterLink}
                  to="/create"
                  state={{
                    customer: customer,
                    items: []
                  }}
                  color="primary"
                  sx={{ backgroundColor: "#DEDEDE" }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box my={2}>
            <Tabs value={tab} sx={{ borderBottom: "1px solid #ECECEC" }}>
              <Tab key={0} label={"Profile"} onClick={() => setTab(0)} />
              {customer.phone && <Tab key={1} label={"Orders"} onClick={() => setTab(1)} />}
              {hasQuoteAccess && customer.phone && <Tab key={2} label={"Quotes"} onClick={() => setTab(2)} />}
            </Tabs>
          </Box>
          {tab === 0 && (
            <Box>
              <Paper elevation={0}>
                <Box p={2}>
                  <Box>
                    <Typography variant="overline" color="textSecondary">
                      First Name
                    </Typography>
                    <Typography variant="body1">{customer.firstName}</Typography>
                  </Box>
                  <Box my={2}>
                    <Typography variant="overline" color="textSecondary">
                      Last Name
                    </Typography>
                    <Typography variant="body1">{customer.lastName}</Typography>
                  </Box>
                  {customer?.loyaltyLevel && (
                    <Box my={2}>
                      <Typography variant="overline" color="textSecondary">
                        LOYALTY STATUS
                      </Typography>
                      <Typography variant="body1">{capitalize(customer?.loyaltyLevel)}</Typography>
                    </Box>
                  )}
                  <Box my={2}>
                    <Typography variant="overline" color="textSecondary">
                      Phone
                    </Typography>
                    {customer.phone && (
                      <Typography variant="body1">
                        {formatPhoneNumber(customer.phone)} <ClickToCopy text={customer.phone} />
                        <ClickToCall phone={customer.phone} />
                      </Typography>
                    )}
                  </Box>
                  <Box my={2}>
                    <Typography variant="overline" color="textSecondary">
                      Email
                    </Typography>
                    <Typography variant="body1">
                      {customer.email || "-"} {customer.email && <ClickToCopy text={customer.email} />}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}
          {tab === 1 && (
            <Box>
              {customer.orders.length > 0 ? (
                customer.orders
                  ?.sort((o1, o2) => o2.fulfillment.date.localeCompare(o1.fulfillment.date))
                  .map((order) => {
                    return <BakerOrderSummary key={order.id} order={order} showTs={true} />;
                  })
              ) : (
                <NothingFound />
              )}
            </Box>
          )}
          {tab === 2 && (
            <Box>
              {customer.quotes.length > 0 ? (
                customer.quotes
                  ?.sort((o1, o2) => o2.date.localeCompare(o1.date))
                  .map((request, idx) => {
                    return <BakerQuoteSummary key={idx} request={request} />;
                  })
              ) : (
                <NothingFound />
              )}
            </Box>
          )}
        </Box>
      </Container>
    );
  }
}
