import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { bakeStreetLogo, placeholderImg } from "../../common/constants";
import moment from "moment";
import { Alert } from "@mui/material";
import { capitalize, formatPhoneNumber, generateSizeSummary, getCustomerName, getStartTime } from "../../common/util";
import { UserContext } from "../../context/UserContext";
import HTMLViewer from "../../common/component/HTMLViewer";
import OrderPrintLineItems from "./print/OrderPrintLineItems";
import { STORAGE_BUCKET, IMG_PLACEHOLDER } from "../../common/constants";
import UserAvatar from "../../common/UserAvatar";

export default function OrderPrintVersion(props) {
  const { user } = useContext(UserContext);
  const { type } = props;

  const orderDetails = props.order.details;
  if (orderDetails.pics && orderDetails.pics.length === 0) {
    orderDetails.pics.push(placeholderImg);
  }

  const { customer, fulfillment, kitchen, invoice, items } = props.order;
  const customerName = getCustomerName(customer);

  let readyByTime = fulfillment.time || "";
  if (user.acctId === "RaKfruZdNNMhNqd0ZF65" || user.acctId === "-MX2oaNrh4TcKVvnhcT9") {
    const startHour = getStartTime(fulfillment.time);
    if (startHour > 0) {
      let fulfillmentHour = startHour - 1;
      if (moment(fulfillment.date).isoWeekday() === 6) {
        // override for Saturday
        fulfillmentHour = startHour - 3;
      }
      readyByTime = `${fulfillmentHour % 12 || 12}${fulfillmentHour < 12 ? "am" : "pm"}`;
    }
  }

  let alert = (
    <Box sx={{ marginBottomL: "5pt" }}>
      {props.order.paymentType === "offline" && (
        <Box>
          <Alert severity="error">Offline Order - BakeStreet did not collect any payment</Alert>
        </Box>
      )}
    </Box>
  );

  const header = (
    <Box sx={{ marginBottom: "10pt" }}>
      <Box sx={{ borderBottom: "1pt solid #DDD", paddingBottom: "5pt" }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h6">{customerName}</Typography>
            <Typography variant="body1">{formatPhoneNumber(customer.phone)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">{`${moment(fulfillment.date).format("ddd, MMM D")} `}</Typography>
            <Typography variant="body1">{readyByTime}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">{capitalize(fulfillment.type)}</Typography>
            {fulfillment.type === "pickup" && (
              <Typography
                variant="body1"
                sx={{ overflow: "hidden", display: "-webkit-box", "-webkit-line-clamp": "1", "line-clamp": "1", "-webkit-box-orient": "vertical" }}
              >
                {fulfillment.location?.street || fulfillment.address?.split(",")[0]}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3} align="right">
            <Box>
              <img alt="logo" src={bakeStreetLogo} style={{ maxHeight: "16pt", marginBottom: "3pt" }}></img>
            </Box>
            <UserAvatar uid={props.order.uid} style={{ width: "24px", height: "24px" }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  let chromeAgent = window.navigator.userAgent.indexOf("Chrome") > -1;
  let safariAgent = window.navigator.userAgent.indexOf("Safari") > -1;
  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }

  const detail = (
    <Box p={1} align="left" style={{ borderTop: "1px solid #EEE" }}>
      <Grid container spacing={1}>
        {props.order.type !== "shop" && (
          <Grid item xs={12}>
            <Typography variant="h6">{orderDetails.title}</Typography>
          </Grid>
        )}
        <Grid item xs={3}>
          <Typography variant="overline" color="textSecondary">
            SIZE
          </Typography>
          <Typography variant="body1">{`${props.order.details.shape || ""} ${props.order.details.size}`}</Typography>
          <Box mt={1}>
            <Typography variant="overline" color="textSecondary">
              SERVINGS
            </Typography>
            <Typography variant="body1">{props.order.details.servings || "-"}</Typography>
          </Box>
          <Box mt={1}>
            <Typography variant="overline" color="textSecondary">
              FLAVOR
            </Typography>
            <Typography variant="body1">{props.order.details.flavor || "-"}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="overline" color="textSecondary">
            FILLING
          </Typography>
          <Typography variant="body1">{props.order.details.filling || "-"}</Typography>
          <Box my={1}>
            <Typography variant="overline" color="textSecondary">
              FROSTING
            </Typography>
            <Typography variant="body1">{props.order.details.frosting || "-"}</Typography>
          </Box>
          <Box my={1}>
            <Typography variant="overline" color="textSecondary">
              TAGS
            </Typography>
            <Box>
              <Typography variant="body1">{props.order.details.tags?.join(", ") || "-"}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="overline" color="textSecondary">
            INSCRIPTION
          </Typography>
          <Typography variant="body1">{props.order.details.inscription || "-"}</Typography>
          <Box mt={1}>
            <Typography variant="overline" color="textSecondary">
              NOTES
            </Typography>
            <Box>
              <HTMLViewer content={(props.order.details.decor && props.order.details.decor.trim()) || "-"} style={{ fontSize: ".85rem" }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const kitchenInstructions = (
    <Box>
      {kitchen && (
        <Box mt={1} py={1} sx={{ borderTop: "1px solid #DDD" }}>
          <Typography variant="overline" color="textSecondary">
            INSTRUCTIONS
          </Typography>
          <HTMLViewer content={kitchen.notes} style={{ fontSize: "1rem" }} />
        </Box>
      )}
    </Box>
  );

  let addOns = <Box />;
  if (invoice) {
    const activeAddons = Object.entries(invoice).filter(([k, v]) => v.status !== "void");
    if (activeAddons?.length > 0) {
      console.log("activeAddsOns", activeAddons);
      addOns = (
        <Box>
          <Box mt={1} py={1} sx={{ borderTop: "1px solid #DDD" }}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {activeAddons.map(([key, value]) => {
                  return (
                    <Grid item xs={6}>
                      <Typography variant="overline" color="textSecondary">
                        ADD-ON
                      </Typography>
                      <Typography variant="body1">{value.description}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    }
  }

  const footer = (
    <Box align="center" sx={{ borderTop: "1pt solid #DDD", paddingTop: "5pt", marginTop: "5pt" }}>
      <Typography variant="overline" color="textSecondary">
        {customerName} ● {moment(fulfillment.date).format("ddd, MMM D")} [{readyByTime}] ● {capitalize(fulfillment.type)}
      </Typography>
    </Box>
  );

  if (props.order.src === "direct" && props.order.items) {
    return (
      <Box>
        {type === "compact" && (
          <Box>
            <div style={{ height: "9in" }}>
              {header}
              {alert}
              <OrderPrintLineItems items={items} type={type} />
              {addOns}
              {kitchenInstructions}
            </div>
            {footer}
          </Box>
        )}
        {type === "expanded" &&
          items.map((item, idx) => {
            return (
              <Box key={idx}>
                {idx > 0 && <div class="pagebreak"> </div>}
                <div style={{ height: "9in" }}>
                  <Box style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    {header}
                    {alert}
                    <Box p={1} align="center" style={{ flexGrow: "1", overflow: "hidden", height: "100%" }}>
                      <Box sx={{ display: "flex", height: "100%" }}>
                        {item.images &&
                          item.images.length > 0 &&
                          item.images.map((o, idx) => {
                            return (
                              <Box sx={{ maxWidth: `${Number(100 / item.images.length)}%`, maxHeight: "100%" }}>
                                <img src={o} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                              </Box>
                            );
                          })}
                        {(!item.images || item.images.length === 0) && (
                          <img src={`${STORAGE_BUCKET}${IMG_PLACEHOLDER}`} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                        )}
                      </Box>
                    </Box>
                    <OrderPrintLineItems items={[item]} type={type} />
                    <Box align="left" style={{ borderTop: "1px solid #EEE", paddingTop: "5pt", paddingBottom: "15pt" }}>
                      <Grid container spacing={1}>
                        {item.variant?.size && (
                          <Grid item xs={6}>
                            <Typography variant="overline" color="textSecondary">
                              SIZE
                            </Typography>
                            <Typography variant="body1">{generateSizeSummary(item.variant?.size)}</Typography>
                          </Grid>
                        )}
                        {item?.variant?.frosting && (
                          <Grid item xs={6}>
                            <Typography variant="overline" color="textSecondary">
                              FROSTING
                            </Typography>
                            <Typography variant="body1">{item?.variant?.frosting.name}</Typography>
                          </Grid>
                        )}
                      </Grid>
                      {Array.isArray(item?.variant?.flavor) &&
                        item?.variant?.flavor?.map((o, idx) => {
                          return (
                            <Grid container spacing={1} sx={{ paddingTop: "2pt" }}>
                              {o.name && (
                                <Grid item xs={6}>
                                  <Typography variant="overline" color="textSecondary">
                                    FLAVOR
                                  </Typography>
                                  <Typography variant="body1">{o.name}</Typography>
                                </Grid>
                              )}
                              {o.filling && (
                                <Grid item xs={6}>
                                  <Typography variant="overline" color="textSecondary">
                                    FILLING
                                  </Typography>
                                  <Typography variant="body1">{o.filling}</Typography>
                                </Grid>
                              )}
                            </Grid>
                          );
                        })}
                      {typeof item?.variant?.flavor === "object" && (
                        <Grid container spacing={1} sx={{ paddingTop: "2pt" }}>
                          {item?.variant?.flavor?.base && (
                            <Grid item xs={6}>
                              <Typography variant="overline" color="textSecondary">
                                FLAVOR
                              </Typography>
                              <Typography variant="body1">{item.variant.flavor.base.name}</Typography>
                            </Grid>
                          )}
                          {item?.variant?.flavor?.filling && (
                            <Grid item xs={6}>
                              <Typography variant="overline" color="textSecondary">
                                FILLING
                              </Typography>
                              <Typography variant="body1">{item.variant.flavor.filling.name}</Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {item.variant &&
                        Object.entries(item.variant).map(([key, value]) => {
                          if (!["size", "flavor", "frosting", "add-on"].includes(key)) {
                            return (
                              <Grid item xs={6}>
                                <Typography variant="overline" color="textSecondary">
                                  {key}
                                </Typography>
                                <Typography variant="body1">{value?.name}</Typography>
                              </Grid>
                            );
                          }
                        })}
                      {item.variant?.["add-on"] && (
                        <Grid item xs={6}>
                          <Typography variant="overline" color="textSecondary">
                            Add-Ons
                          </Typography>
                          <Typography variant="body1">{item.variant?.[`add-on`].map((o) => o.name).join(", ")}</Typography>
                        </Grid>
                      )}

                      <Grid container spacing={2} sx={{ paddingTop: "5pt" }}>
                        {item.customization?.inscription && (
                          <Grid item xs={12}>
                            <Typography variant="overline" color="textSecondary">
                              INSCRIPTION
                            </Typography>
                            <Typography variant="body1">{item.customization.inscription?.text}</Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          {kitchenInstructions}
                        </Grid>

                        {(item.description || item.tags) && (
                          <Grid item xs={12}>
                            <Typography variant="overline" color="textSecondary">
                              NOTES
                            </Typography>
                            {item.tags && item.tags.length > 0 && <Typography variant="body2">{item.tags?.join(", ")}</Typography>}
                            <HTMLViewer content={item.description} style={{ fontSize: ".85rem" }} />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    {addOns}
                  </Box>
                </div>
                {footer}
                {/*item.images &&
                  item.images.map((o, idx) => {
                    return (
                      <Box>
                        <div class="pagebreak"> </div>
                        <div style={{ height: "9in" }}>
                          <Box key={idx} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                            <Box>{header}</Box>
                            <Box p={1} align="center" style={{ flexGrow: "1", overflow: "hidden", height: "100%" }}>
                              <img
                                src={o}
                                style={{ height: "100%", maxWidth: "100%", maxHeight: "100%", objectFit: "contain", borderRadius: "4px" }}
                              />
                            </Box>
                          </Box>
                        </div>
                        {footer}
                      </Box>
                    );
                  })*/}
              </Box>
            );
          })}
      </Box>
    );
  } else {
    return (
      <Box>
        {orderDetails.pics.map((pic, idx) => {
          return (
            <div style={{ height: "8.8in", pageBreakAfter: orderDetails.pics.length > +idx + 1 ? "always" : "auto" }}>
              <Box key={idx} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <Box>
                  {header}
                  {alert}
                </Box>
                <Box align="center" style={{ flexGrow: "1", overflow: "hidden", height: "100%" }}>
                  <img src={pic} style={{ height: "100%", maxWidth: "100%", maxHeight: "100%", objectFit: "contain", borderRadius: "4px" }} />
                  {orderDetails.pics.length > 1 && (
                    <Box>
                      <Typography variant="overline">
                        {idx + 1} of {orderDetails.pics.length} photos
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {detail}
                  {addOns}
                  {kitchenInstructions}
                  {footer}
                </Box>
              </Box>
            </div>
          );
        })}
      </Box>
    );
  }
}
