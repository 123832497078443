import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { capitalize, generateFlavorSummary, generateSizeSummary } from "../../../common/util";
import { IMG_PLACEHOLDER, STORAGE_BUCKET } from "../../../common/constants";
import HTMLViewer from "../../../common/component/HTMLViewer";
import currency from "currency.js";

export default function OrderReceiptPrintLineItems(props) {
  const { items } = props;

  return (
    <Box py={2} sx={{ borderTop: "1px dashed #DEDEDE", borderBottom: "1px dashed #DEDEDE" }}>
      {items &&
        items.map((item, idx) => {
          return (
            <Box key={idx} mt={1}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <img
                      src={item.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`}
                      style={{ width: "100%", aspectRatio: "1", objectFit: "contain" }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">
                      {item.quantity} x {item.title}
                    </Typography>
                    <Box>
                      <Typography variant="body2" gutterBottom>
                        {generateSizeSummary(item.variant?.size)}
                        {item?.variant?.frosting && ` • ${item.variant.frosting.name}`}
                      </Typography>
                      <Box>
                        <Typography variant="body2" gutterBottom>
                          {generateFlavorSummary(item.variant?.flavor)}
                        </Typography>
                        {item.variant &&
                          Object.entries(item.variant).map(([key, value]) => {
                            if (!["size", "flavor", "frosting", "add-on"].includes(key)) {
                              return (
                                <Typography variant="body2" display="inline" gutterBottom>
                                  {`${capitalize(key)}: ${value?.name} • `}
                                </Typography>
                              );
                            }
                          })}
                        {item.variant?.["add-on"] && (
                          <Box pt={0.5}>
                            <Typography variant="body2">Add-Ons: {item.variant?.[`add-on`].map((o) => o.name).join(", ")}</Typography>
                          </Box>
                        )}
                        {item.customization?.inscription?.text && (
                          <Typography variant="body2" gutterBottom>
                            Inscription: {item.customization.inscription?.text}
                          </Typography>
                        )}
                        {item.tags && <Typography variant="body2">{item.tags?.join(", ")}</Typography>}
                        <HTMLViewer content={item.description} style={{ fontSize: ".85rem" }} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2} textAlign={"right"}>
                    <Typography variant="overline">{currency(item.total).format()}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}
