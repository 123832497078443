import React, { useState, useRef } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { getCustomerName, getFormattedAddress } from "../../common/util";
import OrderReceiptPrintLineItems from "../../order/component/print/OrderReceiptPrintLineItems";
import { BAKER_PIC_FOLDER } from "../../common/constants";
import currency from "currency.js";
import OrderCancelationPolicy from "../../order/component/OrderCancelationPolicy";
import LatePaymentPolicy from "../../order/component/LatePaymentPolicy";
import OrderRefundPolicy from "../../order/component/OrderRefundPolicy";
import CloseIcon from "@mui/icons-material/Close";

export default function OrderReceiptPrint(props) {
  const printRef = useRef();
  const { order } = props;

  const [open, setOpen] = useState(false);

  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${getCustomerName(order.customer)} ${moment(order.fulfillment.date).format("MMM DD, YYYY")}`
  });

  const handlePrint = () => {
    print();
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} fullWidth color="secondary" variant="contained">
        Print Order Receipt
      </Button>
      {open && (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
          <DialogTitle>
            Receipt • {getCustomerName(order.customer)} • {moment(order.fulfillment.date).format("MMM DD, YYYY")}
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box mt={2} ref={printRef}>
              <Box textAlign={"center"} mb={2}>
                <img style={{ width: "50px", height: "50px", borderRadius: "5px" }} src={`${BAKER_PIC_FOLDER}/${order.baker.id}/logo400.webp`} />
                <Typography>{order.baker.name}</Typography>
              </Box>
              <Box>
                <OrderReceiptPrintLineItems items={order.items} />
                <Box py={2} sx={{ borderBottom: "1px dashed #DEDEDE" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="body2">{order.fulfillment.type === "delivery" ? "Delivery" : "Store Pickup"}</Typography>
                      <Typography variant="body2">{getFormattedAddress(order.fulfillment.location)}</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={"right"}>
                      <Typography variant="overline">{currency(order.payment.delivery || "0").format()}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box py={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="body2">Tip</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={"right"}>
                      <Typography variant="overline">{currency(order.payment.tip || "0").format()}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box py={2} sx={{ borderTop: "1px dashed #DEDEDE", borderBottom: "1px dashed #DEDEDE" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="body2" sx={{ fontWeight: "700" }}>
                        TOTAL AMOUNT DUE
                      </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={"right"}>
                      <Typography variant="overline">{currency(order.payment.total).format()}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box py={2} sx={{ borderBottom: "1px dashed #DEDEDE" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="body2">Paid</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={"right"}>
                      <Typography variant="overline">({currency(order.payment.paid).format()})</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body2">Balance Due</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={"right"}>
                      <Typography variant="overline">{currency(order.payment.due || "0").format()}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box my={2}>
                <OrderCancelationPolicy
                  date={order.fulfillment.date}
                  total={order.payment.total}
                  tip={order.payment.tip}
                  delivery={order.payment.delivery}
                  ts={order.ts._seconds * 1000}
                />
                <LatePaymentPolicy />
                <OrderRefundPolicy />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={handlePrint} fullWidth>
              PRINT
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
