import React, { useState, useEffect, useContext } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { getAmountWithTxnFee, isV2SaaSPlan } from "../../common/util";
import currency from "currency.js";
import { UserContext } from "../../context/UserContext";

export default function CreateAddOn(props) {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const { orderId } = useParams();
  const [order, setOrder] = useState();

  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [includeFees, setIncludeFees] = useState(user.config?.transferFees === 'false' ? false : true);

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    bkstApi(`/my/order/${orderId}`).then((res) => {
      setOrder(res.data);
      setLoading(false);
    });
  }, [orderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    let payload = { description, amount, includeFees };

    if (isV2SaaSPlan(user.plan)) {
      payload.amount = includeFees ? getAmountWithTxnFee(amount) : amount;
    }

    bkstApi.post(`/my/order/${orderId}/send-invoice`, payload).then((res) => {
      navigate(`/order/${orderId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="sm">
          <Box mt={5}>
            <Box mb={4} align="center">
              <Typography variant="h3" gutterBottom>
                Create Order Add-On
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Track changes requested by customer that require additional payment. Things like size upgrade, delivery fee,
                additional items like cupcakes, cookies, etc.
              </Typography>
            </Box>
            <Paper elevation={0}>
              <Box px={3} pt={5} pb={3}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="amount"
                        type="number"
                        value={amount}
                        label="Amount"
                        name="amount"
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(e) => setAmount(e.target.value)}
                        onWheel={(e) => {
                          e.target && e.target.blur();
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="description"
                        value={description}
                        label="Description"
                        name="description"
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(e) => setDescription(e.target.value)}
                        inputProps={{ maxLength: 100 }}
                        helperText="e.g. Delivery fee, 2 dozen cupcakes"
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                    </Grid>
                    {isV2SaaSPlan(user.plan) && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          className="includeFees"
                          control={
                            <Checkbox checked={includeFees} onChange={(e) => setIncludeFees(e.target.checked)} name="consent" color="secondary" />
                          }
                          label="Include transaction fees"
                        />
                        {includeFees && +amount > 0 && (
                          <Grid item xs={12}>
                            <Box p={1} sx={{ backgroundColor: "#e5f6fd", color: "#2980b9" }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant="h6">
                                    {currency(amount).format()} &#8674; {currency(getAmountWithTxnFee(amount)).format()}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography variant="caption">
                                When you click SEND, the amount will be updated to include transaction fee. This updated amount will be shown to the
                                customer.
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    )}
                    <Grid item sm={12} align="right">
                      <Button variant="text" onClick={(e) => navigate(`/order/${orderId}`)} style={{ marginRight: "1rem" }}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Create Add-On
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Paper>
          </Box>
          {processing && (
            <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Container>
      </Box>
    );
  }
}
