import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";

export default function ClickToCall(props) {
  return (
    <Tooltip title={props.phone}>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard.writeText(props.phone);
        }}
        href={`tel:${props.phone}`}
      >
        <PhoneOutlinedIcon style={{ fontSize: "16px", borderBottom: props.verified ? "2px solid #00b894" : "inherit" }} />
      </IconButton>
    </Tooltip>
  );
}
