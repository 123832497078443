import React, { useContext, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { UserContext } from "../../context/UserContext";
import { getSaasWebsiteUrl } from "../util";

export default function ShareButton(props) {
  const { user } = useContext(UserContext);

  const { type, link } = props;
  const saasDomainUrl = getSaasWebsiteUrl(user.domain);
  const url = `${saasDomainUrl}${link}`;

  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(url);
    setOpen(false);
  };

  return (
    <>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <ShareIcon />
      </IconButton>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          {`Share Link`}{" "}
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Typography
              variant="body1"
              color="textSecondary"
            >{`Click the button below to copy the link (url) to the ${type} on your website`}</Typography>
            <Box mt={2}>
              <Paper elevation={0} sx={{ backgroundColor: "#F7F7F7", overflowWrap: "break-word" }}>
                <Box px={2} py={4}>
                  <Typography variant="body1">{url}</Typography>
                </Box>
              </Paper>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<ContentCopyOutlinedIcon />} variant="contained" color="primary" fullWidth onClick={handleClick}>
            Copy Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
