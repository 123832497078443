import React from "react";
import { Box, Typography } from "@mui/material";

export default function OrderRefundPolicy(props) {
  return (
    <Box my={2} id="refund-policy">
      <Typography variant="body1">Returns & Refund policy</Typography>
      <Typography variant="caption" color="textSecondary">
        All custom orders are handcrafted, made-to-order and perishable. Each product is hand-made and some design variation should be expected. We
        ask that you closely inspect your order during pickup or delivery. You are solely responsible for any damage to the items thereafter. Custom
        cakes and treats are very fragile and damage can happen if not handled properly during transportation & setup. Damage may also happen due to
        exposure to excessive heat & humidity, which may result in the appearance of cracks, smudges, melted frosting, colors changes and other
        structural & cosmetic issues. We cannot be held liable for any damage when the items are in your possession. Furthermore, due to the highly
        subjective nature of taste, we are unable to offer any refund if you find the taste unsatisfactory.
      </Typography>
    </Box>
  );
}
