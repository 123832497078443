import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { capitalize, generateFlavorSummary, generateSizeSummary } from "../../../common/util";
import { IMG_PLACEHOLDER, STORAGE_BUCKET } from "../../../common/constants";
import HTMLViewer from "../../../common/component/HTMLViewer";

export default function OrderPrintLineItems(props) {
  const { items, type } = props;

  return (
    <Box my={1}>
      {type === "compact" && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <Typography color="textSecondary" variant="overline">
                QTY
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography color="textSecondary" variant="overline">
                ITEM
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color="textSecondary" variant="overline"></Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {items &&
        items.map((item, idx) => {
          return (
            <Box key={idx} mt={1}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        width: "28px",
                        height: "28px",
                        border: "1px solid #DDD",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography variant="h6">{item.quantity} </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h6">{item.title}</Typography>
                    {type === "compact" && (
                      <Box>
                        <Typography variant="body1" gutterBottom>
                          {generateSizeSummary(item.variant?.size)}
                          {item?.variant?.frosting && ` • ${item.variant.frosting.name}`}
                        </Typography>
                        <Box>
                          <Typography variant="body1" gutterBottom>
                            {generateFlavorSummary(item.variant?.flavor)}
                          </Typography>
                          {item.variant && Object.entries(item.variant).map(([key, value]) => {
                            if (!["size", "flavor", "frosting", "add-on"].includes(key)) {
                              return (
                                <Typography variant="body1" display="inline" gutterBottom>
                                  {`${capitalize(key)}: ${value?.name} • `}
                                </Typography>
                              );
                            }
                          })}
                          {item.variant?.["add-on"] && (
                            <Box pt={.5}>
                              <Typography variant="body1">Add-Ons: {item.variant?.[`add-on`].map((o) => o.name).join(", ")}</Typography>
                            </Box>
                          )}
                          {item.customization?.inscription?.text && (
                            <Typography variant="body1" gutterBottom>
                              Inscription: {item.customization.inscription?.text}
                            </Typography>
                          )}
                          {item.tags && <Typography variant="body2">Tags: {item.tags?.join(", ")}</Typography>}
                          { item.description && <HTMLViewer content={`Notes: ${item.description}`} style={{ fontSize: ".85rem" }} /> }
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  {type === "compact" && (
                    <Grid item xs={2} align="right">
                      <img src={item.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`} style={{ height: "100px", objectFit: "contain" }} />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}
