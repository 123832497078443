import React from "react";
import { Box, Container, Typography } from "@mui/material";

export default function ReleaseNotes(props) {
  return (
    <Container maxWidth="sm">
      <Box>
        <Box my={6}>
          <Typography variant="h4" gutterBottom>
            Release Notes
          </Typography>
          <Typography variant="body1" color="textSecondary">
            A summary of some of the recent changes to your BakeStreet app. Learn about new features, updates to existing functionality & bug-fixes.
          </Typography>
        </Box>
        <iframe
          src="https://docs.google.com/document/d/e/2PACX-1vTF62PP8EHyrbhWS2q-LeS_vHQpeMFECw9B6XcSdNbk3Fnawkq0LB4w0xc83uTwOSBrXcUEwL_FBS5H/pub?embedded=true"
          style={{ width: "100%", height: "1000vh", border: "0" }}
        ></iframe>
      </Box>
    </Container>
  );
}
