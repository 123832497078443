import React from "react";
import { Box, Typography } from "@mui/material";
import { STORAGE_BUCKET } from "../constants";

export default function NothingFound(props) {
  return (
    <Box my={2} px={2} pb={2} textAlign={"center"} sx={{ border: "1px solid #DDD" }}>
      <img style={{ width: "250px" }} src={`${STORAGE_BUCKET}/img/icon/search-results.svg`} />
      <Typography variant="subtitle1">{props.title || "Nothing found"}</Typography>
      <Typography variant="body1" color="textSecondary">
        {props.subtitle}
      </Typography>
    </Box>
  );
}
