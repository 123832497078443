import React, { useContext } from "react";
import { Avatar, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import currency from "currency.js";
import { UserContext } from "../../context/UserContext";
import { hasManagerAccess, capitalize } from "../../common/util";
import OrderRefund from "../screen/OrderRefund";
import Transfers from "./Transfers";
import moment from "moment";
import ShowMore from "../../common/component/ShowMore";
import { STORAGE_BUCKET } from "../../common/constants";
import BalancePaymentDate from "./BalancePaymentDate";
import ShareButton from "../../common/component/ShareButton";

export default function BakerOrderPayment(props) {
  const { order } = props;
  const { user } = useContext(UserContext);

  let isManager = hasManagerAccess(user.role) || user.role === "kitchen leader";

  let dueDate = moment(order.fulfillment.date).subtract(7, "days");
  if (order.payment.dueDate) {
    dueDate = moment(order.payment.dueDate);
  }

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Typography variant="overline" color="textSecondary">
            Payment Details
          </Typography>
        </Grid>
        <Grid item xs={4} align="right">
          {isManager && order.src === "direct" && order.paymentType !== "offline" && <Transfers data={order.transfers} />}
          {+order.payment.due > 0 && isManager && order.src === "direct" && order.paymentType !== "offline" && (
            <ShareButton type="balance payment page for this order" link={`/order/${order.id}/balance-payment`} />
          )}
          {isManager && order.src === "direct" && order.paymentType !== "offline" && <OrderRefund order={order} />}
        </Grid>
      </Grid>
      <Paper elevation={0}>
        <Box px={2} py={3}>
          {order.payment.subtotal > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography variant="overline" color="textSecondary">
                  {currency(order.payment.subtotal).format()}
                </Typography>
              </Grid>
            </Grid>
          )}
          {order.payment.delivery > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Delivery
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography variant="overline" color="textSecondary">
                  {currency(order.payment.delivery).format()}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Tip
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography variant="overline" color="textSecondary">
                {currency(order.payment.tip).format()}
              </Typography>
            </Grid>
          </Grid>
          {order.payment.discount > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Discount
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography variant="overline" color="textSecondary">
                  {currency(order.payment.discount).format()}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="overline">Total</Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Typography variant="overline">{currency(order.payment.bakerTotal).format()}</Typography>
            </Grid>
          </Grid>
          {order.src === "direct" && (
            <Box>
              <Box my={2}>
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="overline" color="textSecondary">
                    Amount Paid
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography variant="overline" color="textSecondary">
                    {currency(order.payment.paid).format()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="overline" color="textSecondary">
                    Balance Due {+order.payment.due > 0 && <BalancePaymentDate order={order} />}
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography variant="overline" color="textSecondary">
                    {currency(order.payment.due).format()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          {order.src === "direct" && order.payment.refund && (
            <Box>
              <Box my={1}>
                <Divider />
              </Box>
              <Grid container alignItems={"center"}>
                <Grid item xs={6}>
                  <ShowMore openCta="Refund" closeCta="Refund">
                    {order.payment.refund.map((o) => (
                      <Box>
                        <Typography variant="overline" display="block">
                          {currency(o.amount).format()} Refund
                        </Typography>
                        <Box my={0.25}>
                          <Typography variant="body2" display="block" color="textSecondary">
                            Reason: {capitalize(o.reason)}. {o.note}
                          </Typography>
                        </Box>
                        <Box my={0.25}>
                          <Typography variant="caption" display="block" color="textSecondary">
                            {moment(o.ts).format("LLL")}
                          </Typography>
                        </Box>
                        <Avatar src={`${STORAGE_BUCKET}/img/u/${o.uid}.webp`}></Avatar>
                      </Box>
                    ))}
                  </ShowMore>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="overline" color="error">
                    {currency(
                      order.payment.refund.reduce((acc, obj) => {
                        return +acc + +obj.amount;
                      }, 0)
                    ).format()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
      {order.transferId && (
        <Box my={2} textAlign={"center"}>
          <Typography variant="caption" color="textSecondary" display="block">
            BakeStreet sent your payment on {moment(order.transferTs).format("LLLL")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
