import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import CustomerDetail from "../../customer/screen/CustomerDetail";
import CloseIcon from "@mui/icons-material/Close";

export default function CustomerDetailsButton(props) {
  const { customer } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        sx={{ display: "inline-flex", alignItems: "center", cursor: "pointer", gap: "3px", textDecoration: "none", color: "inherit" }}
        onClick={() => setOpen(true)}
      >
        <IconButton size="small">
          <PermIdentityOutlinedIcon />
        </IconButton>
        <Typography variant="body2">
          {customer?.firstName} {customer?.lastName}
        </Typography>
      </Box>
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle sx={{ margin: 0 }}>
          Customer Profile{" "}
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "90vh", backgroundColor: "#ECECEC", padding: 0, margin: 0 }}>
          <CustomerDetail customerId={customer.id} />
        </DialogContent>
      </Dialog>
    </>
  );
}
